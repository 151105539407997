import moment from "moment";
export default {
    data(){
        return {
            UMS: window.UMS,
            // TODO @KB  remove resourceTypes; this.t('resource.type') funktionert nicht, Ursache noch unklar
            resourceTypes: {
                backup: 'TSM Backup-Service',
                dhcpdns: 'IP-Adressreservierung',
                dns_cname: 'DNS-Alias',
                fileserver: 'Zentraler Dateiserver',
                foreign_domain: 'Fremddomain',
                functional_account: 'Funktionsaccount',
                groupware_ab: 'Adressbücher',
                groupware_cal: 'Gruppenkalender',
                groupware_gr: 'Gruppen',
                groupware_res: 'Ressourcen',
                grypstube: 'Grypstube',
                mail_lists: 'Mailingliste',
                netaccesscontrol: 'Geräteregistrierung',
                nextcloud_folder: 'Nextcloud-Gruppenordner',
                pms_pooladmin_fmz: 'Pooladmin Sprachenzentrum',
                pms_studprint_cfgm_genetik: 'Studentendruck CFGM Genetik',
                pms_studprint_cfgm_genomforschung: 'Studentendruck CFGM Genomforschung',
                pms_studprint_cfgm_mikrobiologie: 'Studentendruck CFGM Mikrobiologie',
                pms_studprint_pharmazie: 'Studentendruck Pharmazie',
                pms_studprint_physik: 'Studentendruck Physik',
                pms_studprint_urz: 'Studentendruck URZ',
                resticbackup: 'Restic Backup-Service',
                tk_conf: 'Konferenzraum',
                tk_fax: 'Analoger Faxanschluss',
                tk_faxserver: 'Elektronische Faxbox',
                tk_mobile: 'Mobilteilnehmer',
                tk_phonefunc: 'Funktionstelefon',
                tk_phonepers: 'Persönliche Telefonnummer',
                typo3_backend: 'Zugriff auf das TYPO3-Backend',
                vpn_genom: 'VPN-Zugriff auf Netzwerk der Genomforschung',
                wordpress: 'Alternativer Webserver',
                zks_part00: '00 General',
                zks_part01: '01 Biochemie',
                zks_part02: '02 CFGM',
                zks_part03: '03 Geowissenschaften',
                zks_part04: '04 Pharmazie',
                zks_part05: '05 Physik',
                zks_part07: '07 Botanik',
                zks_part08: '08 URZ',
                zks_part09: '09 Zoologie',
                zks_part10: '10 CDFI',
                zks_part11: '11 C-DAT/LPG',
                zks_part12: '12 Mathematik',
                zks_part13: '13 Bibliothek',
                zks_part14: '14 Psychologie',
                zks_part16: '16 Theologie',
                zks_part17: '17 Hochschulsport',
                zks_part18: '18 IBZ',
                zks_part19: '19 RSF',
                zks_part99: '99 UMG',
                zks_admin: 'zks:admin',
            },
        }
    },
    computed: {
        user(){
            return this.$store.getters['account/account'];
        },
        avatar(){
            return this.user.jpegPhoto ? this.user.jpegPhoto : this.avatar_default;
        },
        avatar_default(){
            return this.UMS.config('account.default_avatar');
        }
    },
    methods : {
        t(languageKey, params){
            //console.log('t(' + languageKey + ', ' + params + ') = ' + JSON.stringify(this.$store.getters['language/translate'](languageKey, params)));
            return this.$store.getters['language/translate'](languageKey, params);
        },
        tOrTake(string){
            if (string && string.match(/\w+\.\w+/)) {
                return this.t(string);
            }

            return string;
        },
        config(configKey, defaultValue){
            return this.UMS.config(configKey, defaultValue);
        },
        typeByKey(type){
            let types = this.config('resource_types');
            if (!types || !types[type]) {
                return type;
            }
            let parents = this.config('sidebar');
            let typeObject = types[type];
            typeObject.parent = parents[typeObject.label];
            return typeObject;
        },
        lastPasswordChange(time){
            time = parseInt(time);
            if (time === 0) return this.t('account.pwd_change_never');
            let date = moment.unix(0).add(time, 'day');
            let days = moment().diff(date, 'days');
            if (days === 0) return this.t('account.pwd_change_today');
            if (days === 1) return this.t('account.pwd_change_yesterday');
            return this.t('account.pwd_change_days_ago', {days});
        },
        readRessourceType(typeName){
            if (typeName in this.resourceTypes){
                return this.resourceTypes[typeName];
            }else{
                return 'no ressource type definition';
            }
        }
    }
}